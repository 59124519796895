//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DebtDataField from "../components/Debts/DebtDataField.vue";
import DebtFormElement from "../components/Debts/DebtFormElement.vue";
import DocumentsManager from "../components/DocumentsManager.vue";

export default {
    components: {
        DebtDataField,
        DebtFormElement,
        DocumentsManager
    },

    data() {
        return {
            creditors: [],
            creditors_loading: false,
            creditors_loaded: false,
            creditor_name: "",
            creditors_current_page: 1,
            creditors_number_of_pages: 1,

            current_asset_id: "",
            current_creditor: {},
            active_creditor_id: "",

            create_dialog: false,
            create_loading: false,

            confirm_dialog: false,

            update_dialog: false,
            update_loading: false,

            docs_dialog: false,
            docs_dialog_id: "",
            docs_loading: false,

            delete_dialog: false,
            delete_loading: false,

            local_creditors_data: []
        };
    },

    computed: {
        app() {
            return this.$store.getters["application/getApplication"];
        }
    },

    methods: {
        openCreateDialog() {
            this.create_dialog = true;
        },

        closeCreateDialog() {
            this.create_dialog = false;
        },

        openDeleteDialog(id) {
            this.current_asset_id = id;
            this.delete_dialog = true;
        },

        closeDeleteDialog() {
            this.delete_dialog = false;
        },

        openUpdateDialog(item) {
            // this.current_asset_index = index;
            this.current_creditor = item;
            this.update_dialog = true;
        },

        closeUpdateDialog() {
            this.update_dialog = false;
        },

        openDocsDialog(creditor) {
            this.docs_dialog_id = creditor._id;
            this.creditor_name = creditor.name;
            this.docs_dialog = true;
        },
        closeDocsDialog() {
            this.docs_dialog = false;
        },

        openConfirmModal() {
            this.confirm_dialog = true;
        },

        closeConfirmDataModal() {
            this.confirm_dialog = false;
        },

        async addCreditor() {
            if (this.create_loading === true) return;

            if (this.$refs.cfe.validate() !== true) return;

            this.create_loading = true;
            try {
                await this.$axios.$post(`/debts`, {
                    ...this.$refs.cfe.saveData(),
                    application: this.app._id
                });
                this.$message({
                    type: "success",
                    title: "Wierzyciel został dodany",
                    msg: "Znajdziesz go u dołu listy"
                });

                this.closeCreateDialog();
            } catch (error) {
                console.error(error);
            }
            this.create_loading = false;
        },

        async updateCreditor() {
            if (this.update_loading === true) return;

            if (this.$refs.ucfe.validate() !== true) return;

            this.update_loading = true;

            try {
                const data = this.$refs.ucfe.saveData();
                await this.$axios.$put(`/debts/${data._id}`, data);
            } catch (error) {
                console.error(error);
            }

            this.update_dialog = false;
            this.update_loading = false;
        },

        async deleteCreditor() {
            if (this.delete_loading === true) return;

            this.delete_loading = true;

            try {
                await this.$axios.$delete(`/debts/${this.current_asset_id}`);

                this.$message({
                    type: "success",
                    title: "Sukces",
                    msg: "Twoja wierzytelność została usunięta"
                });
            } catch (error) {
                console.error(error);
            }

            this.delete_loading = false;
            this.delete_dialog = false;
        },

        async sendCreditorsData() {
            if (this.sending === true) return;

            if (this.creditors.length < 1) {
                return this.$message({
                    type: "error",
                    title: "Niekompletne dane",
                    msg: "Dodaj co najmniej jednego wierzyciela"
                });
            }

            this.sending = true;

            try {
                await this.$axios.$post(
                    `/applications/${this.$store.getters["application/getApplication"]._id}/confirm-debts`
                );

                this.$message({
                    type: "success",
                    title: "Sukces",
                    msg: "Twoje wierzytelności zostały zatwierdzone"
                });

                this.$router.push({
                    name: "documents"
                });

                this.closeConfirmDataModal();
            } catch (error) {
                console.error(error);
            }
            this.sending = false;
        },

        saveDocuments() {
            if (this.$refs.cdfe.validate() === true) {
                this.closeDocsDialog();
            } else if (typeof this.$refs.cdfe.validate() === "string") {
                this.$message({
                    type: "error",
                    msg: this.$refs.cdfe.validate()
                });
            }
        },

        handleNewDebt(pc) {
            this.creditors.push(pc);
        },

        handleUpdateDebt(pc) {
            const ix = this.creditors.findIndex(asset => asset._id === pc._id);

            if (ix === -1) return;

            const item_copy = { ...this.creditors[ix] };

            this.creditors.splice(ix, 1, {
                ...item_copy,
                ...pc
            });
        },

        handleDeleteDebt(pc) {
            const ix = this.creditors.findIndex(asset => asset._id === pc._id);

            if (ix === -1) return;

            this.creditors.splice(ix, 1);
        },

        onIntersectionObserverIntersect() {
            this.creditors_current_page++;
            this.fetchData();
        },

        async fetchData() {
            if (this.creditors_loading === true) return;

            this.creditors_loading = true;

            try {
                const data = await this.$axios.$get(
                    `/debts/?application=${this.app._id}&page=${this.creditors_current_page}&items_per_page=8`
                );
                this.creditors = this.creditors.concat(data.debts);
                this.creditors_current_page = data.pagination.current_page;
                this.creditors_number_of_pages = data.pagination.number_of_pages;
                this.creditors_loaded = true;
            } catch (error) {
                console.error(error);
            }
            this.creditors_loading = false;
        }
    },

    async mounted() {
        if (!this.$store.state.initial_layout_set) return;
        await this.$store.dispatch("application/awaitForApplication");

        this.fetchData();

        this.$io.on("Debt.created", this.handleNewDebt);
        this.$io.on("Debt.updated", this.handleUpdateDebt);
        this.$io.on("Debt.deleted", this.handleDeleteDebt);
    },

    beforeDestroy() {
        this.$io.off("Debt.created", this.handleNewDebt);
        this.$io.off("Debt.updated", this.handleUpdateDebt);
        this.$io.off("Debt.deleted", this.handleDeleteDebt);
    },

    metaInfo: {
        title: "Wierzytelności"
    }
};
