//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpComboDateInput from "@/components/PpComboDateInput.vue";
import countries from "@/static/countries.json";
import GeneralAddressFormElement from "../GeneralAddressFormElement.vue";

export default {
    components: {
        PpComboDateInput,
        GeneralAddressFormElement
    },

    props: {
        label: {
            type: String
        },
        value: {
            type: Object
        }
    },

    data() {
        return {
            local_data: {
                name: "",
                address: null,
                value: "",

                is_payoffing: false,
                last_payoff_date: "",
                bailiff_enforcement: false,
                bailiff_description: "",

                civil_proceedings: false,
                civil_proceedings_description: "",

                is_valid: true,
                err_msg: "Błąd dokumentu"
            },
            country_select_search: ""
        };
    },

    methods: {
        saveData() {
            return this.local_data;
        },

        handleValue() {
            if (this.value) {
                this.local_data = JSON.parse(JSON.stringify(this.value));
            }
        },

        validate() {
            const validation_inputs = [
                this.$refs.creditor_name.validate(),
                this.$refs.creditor_value.validate(),
                this.$refs.address_form.validate()
            ];

            if (this.local_data.is_payoffing === true) {
                validation_inputs.push(this.$refs.last_payoff_date_input.validate());
            }

            if (this.local_data.bailiff_enforcement === true) {
                validation_inputs.push(this.$refs.bailiff_description_input.validate());
            }

            if (this.local_data.civil_proceedings === true) {
                validation_inputs.push(this.$refs.civil_proceedings_description_input.validate());
            }

            if (validation_inputs.indexOf(false) !== -1) {
                return false;
            }
            return true;
        }
    },

    watch: {
        value() {
            this.handleValue();
        }
    },

    mounted() {
        this.handleValue();
    }
};
