var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"debt-form-element"},[_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"creditor_name",attrs:{"type":"text","label":"Nazwa wierzyciela","placeholder":"Nazwa","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    else if (v.length > 63)
                        { return 'Maksymalna długość nazwy wierzyciela to 63 znaki'; }
                    return true;
                }
            ],"show-asterisk":""},model:{value:(_vm.local_data.name),callback:function ($$v) {_vm.$set(_vm.local_data, "name", $$v)},expression:"local_data.name"}})],1),_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"creditor_value",attrs:{"type":"text","label":"Wys. wierzytelności","placeholder":"np. 5 000 zł","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (v.length > 31) { return 'Maksymalna długość kwoty to 31 znaków'; }
                    if (!this$1.$store.state.REGEX_IS_MONEY.test(v))
                        { return 'Proszę podać prawidłową kwotę'; }
                    return true;
                }
            ],"show-asterisk":""},model:{value:(_vm.local_data.value),callback:function ($$v) {_vm.$set(_vm.local_data, "value", $$v)},expression:"local_data.value"}})],1),_c('h4',{staticClass:"debt-form-element__title"},[_vm._v("Adres wierzyciela")]),_c('div',{staticClass:"debt-form-element__storage-location-wrapper"},[_c('GeneralAddressFormElement',{ref:"address_form",model:{value:(_vm.local_data.address),callback:function ($$v) {_vm.$set(_vm.local_data, "address", $$v)},expression:"local_data.address"}})],1),_c('h4',{staticClass:"debt-form-element__title my-4"},[_vm._v("Dodatkowe informacje o zadłużeniu")]),_c('div',{staticClass:"mb-4"},[_c('WkCheckbox',{attrs:{"label":"Wierzytelność jest spłacana"},on:{"change":function (v) { return (v ? _vm.$emit('click:bottom-checkbox') : false); }},model:{value:(_vm.local_data.is_payoffing),callback:function ($$v) {_vm.$set(_vm.local_data, "is_payoffing", $$v)},expression:"local_data.is_payoffing"}})],1),(_vm.local_data.is_payoffing)?_c('div',{staticClass:"mb-4"},[_c('PpComboDateInput',{ref:"last_payoff_date_input",staticClass:"mb-6",attrs:{"label":"Data ostatniej wpłaty","show-asterisk":""},model:{value:(_vm.local_data.last_payoff_date),callback:function ($$v) {_vm.$set(_vm.local_data, "last_payoff_date", $$v)},expression:"local_data.last_payoff_date"}})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('WkCheckbox',{attrs:{"label":"Egzekucja komornicza"},on:{"change":function (v) { return (v ? _vm.$emit('click:bottom-checkbox') : false); }},model:{value:(_vm.local_data.bailiff_enforcement),callback:function ($$v) {_vm.$set(_vm.local_data, "bailiff_enforcement", $$v)},expression:"local_data.bailiff_enforcement"}})],1),(_vm.local_data.bailiff_enforcement)?_c('div',{staticClass:"mb-4"},[_c('WkTextarea',{ref:"bailiff_description_input",staticClass:"mb-4",attrs:{"label":"Opis egzekucji komorniczej","rows":8,"show-asterisk":"","counter":"1023","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (v.length > 1023) { return 'Maksymalna długość opisu to 1023 znaki'; }
                    return true;
                }
            ]},model:{value:(_vm.local_data.bailiff_description),callback:function ($$v) {_vm.$set(_vm.local_data, "bailiff_description", $$v)},expression:"local_data.bailiff_description"}})],1):_vm._e(),_c('div',[_c('WkCheckbox',{attrs:{"label":"Postępowanie cywilne"},on:{"change":function (v) { return (v ? _vm.$emit('click:bottom-checkbox') : false); }},model:{value:(_vm.local_data.civil_proceedings),callback:function ($$v) {_vm.$set(_vm.local_data, "civil_proceedings", $$v)},expression:"local_data.civil_proceedings"}})],1),(_vm.local_data.civil_proceedings)?_c('div',{staticClass:"mt-4"},[_c('WkTextarea',{ref:"civil_proceedings_description_input",attrs:{"label":"Opis postępowania cywilnego","rows":8,"show-asterisk":"","counter":"1023","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (v.length > 1023) { return 'Maksymalna długość opisu to 1023 znaki'; }
                    return true;
                }
            ]},model:{value:(_vm.local_data.civil_proceedings_description),callback:function ($$v) {_vm.$set(_vm.local_data, "civil_proceedings_description", $$v)},expression:"local_data.civil_proceedings_description"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }