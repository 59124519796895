import { render, staticRenderFns } from "./DebtDataField.vue?vue&type=template&id=f8681d44&"
import script from "./DebtDataField.vue?vue&type=script&lang=js&"
export * from "./DebtDataField.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports