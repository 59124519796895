//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: Object
        }
    },

    data() {
        return {
            is_valid: this.value.is_valid,
            err_msg: this.value.err_msg
        };
    },

    methods: {
        deleteAction() {
            this.$emit("delete");
        },

        editAction() {
            this.$emit("edit");
        },

        showDocsAction() {
            this.$emit("show");
        },

        getDebtInfo(debt_details) {
            return `${debt_details.value} • ${debt_details.address.street} ${
                debt_details.address.house_no
            }${debt_details.address.apartment_no ? "/" + debt_details.address.apartment_no : ""} ${
                debt_details.address.city
            } ${debt_details.address.zip_code}`;
        }
    }
};
